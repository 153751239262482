import React from "react";
import { IMAGE } from "../assests/images";
import "../styles/style.css";
import ReviewPage from "./Review";

const FeaturePage = () => {
  return (
    <>
      <div className="feature-page p-lg-5 p-3 pb-lg-0" id="Features">
        <div className="container">
          <div className="feature-page-content">
            <h1 className="featurelogotext">Features</h1>
          </div>
          <div className="ps-4 pb-4 w-lg-50 text-center container align-content-center">
            <p className="featurepara">
              Discover the innovative tools designed to enhance your journey
            </p>
          </div>
          <div className="container">
            <div className="row gap-5 gap-md-0">
              <div className="col-lg-4 d-flex align-items-center justify-content-center">
                <div className="firstpart">
                  <div class="item">
                    <div className="position-relative">
                      <div className="cardText">
                        <h4>Compare Prices</h4>
                        <p>
                          Instantly compare prices at your nearby grocery stores.
                        </p>
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="cardText">
                        <h4>Smart Substitutions</h4>
                        <p>
                          Automatically get suggestions for cheaper brands to maximize savings.
                        </p>
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="cardText">
                        <h4>Real-Time Updates</h4>
                        <p>
                          Get the latest prices on <strong>500,000+ products.</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <img src={IMAGE.featureMobile} alt="featuremobile" className="img-fluid" />
              </div>

              <div className="col-lg-4 d-flex align-items-center justify-content-center">
                <div className="firstpart">
                  <div class="item">
                    <div className="position-relative">
                      <div className="cardText">
                        <h4>Weekly Savings</h4>
                        <p>
                          Save an average of <strong>$25 per order.</strong>
                        </p>
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="cardText">
                        <h4>Shopping Lists</h4>
                        <p>
                          Create and manage customized lists with your weekly products.
                        </p>
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="cardText">
                        <h4>User-Friendly Interface</h4>
                        <p>
                          Easily search and compare prices in your area.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReviewPage />
    </>
  );
};

export default FeaturePage;
