import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { IMAGE } from "../assests/images";
import DownloadPage from "./Download";
import "../styles/style.css";

const ReviewPage = () => {
  const options = {
    items: 3,
    loop: false,
    margin: 20,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide nav-button prev-bg'><i class='bi bi-arrow-left'></i></div>",
      "<div class='nav-btn next-slide nav-button next-bg'><i class='bi bi-arrow-right'></i></div>"
    ],
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      767: {
        items: 2,
      },
      991: {
        items: 3,
      },
    },
  };

  const testimonials = [
    {
      text: "Price Peek has completely changed the way I shop. I used to visit multiple stores, but now I know exactly where to go for the best deals.",
      name: "Sarah W.",
      location: "Passaic, NJ"
    },
    {
      text: "I’ve saved over $200 in just the last few weeks! It’s a game changer for anyone on a budget.",
      name: "Joel L.",
      location: "Brooklyn, NY"
    },
    {
      text: "Love this app! It’s so easy to use, and I’ve noticed my grocery bill drop by at least 20%.",
      name: "Yaakov M. R.",
      location: "Brooklyn, NY"
    },
    {
      text: "With Price Peek, I no longer have to guess if I’m getting the best deal—it does all the work for me!.",
      name: "David M.",
      location: "Monsey, NY"
    },
    {
      text: "Amazing app! I’ve recommended it to all my friends. It’s helped me stick to my budget every month.",
      name: "Rivky S.",
      location: "Crown Heights"
    },
    {
      text: "As a parent, every penny counts. Price Peek has helped me cut down on grocery costs without sacrificing quality, by automatically substituting products for cheaper brands.",
      name: "Leiby P.",
      location: "Brooklyn, NY"
    },
    {
      text: "I used to overspend on groceries all the time. Now, I always know where to find the lowest prices.",
      name: "Abe T.",
      location: "Lakewood, NJ"
    },
    {
      text: "Price Peek makes price comparison effortless. I’ve been using it for months and love the savings!",
      name: "Moshe Hersh A.",
      location: "Upstate, NY"
    },
    {
      text: "I save an average of $25 every week thanks to Price Peek!",
      name: "Isaac F.",
      location: "Monsey, NY"
    },
    {
      text: "Price Peek has been a lifesaver for me. I’ve found the best deals across all my local stores!",
      name: "Perry K.",
      location: "Toms River"
    },
    {
      text: "Living in Brooklyn, Price Peek makes it easy to compare prices at my favorite stores and save money every week.",
      name: "Chaim M.",
      location: "Brooklyn, NY"
    },
    {
      text: "I never realized how much I could save on groceries until I started using Price Peek. It’s a must-have app!",
      name: "Israel M. G.",
      location: "Monroe, NY"
    },
  ];
  return (
    <>
      <div className="reviewpage-container p-lg-5 p-2" id="Review">
      <div className="container">
        <div className="feature-page-content mt-lg-3 mt-0">
          <h1 className="reviewicon">
            Client Satisfaction
          </h1>
        </div>
        <div className="text-center mb-lg-5 mb-0">
          <p className="aboutheading">
            Committed to exceeding your expectations every step of the way
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <OwlCarousel className="owl-theme" {...options}>
                {testimonials.map((testimonial, index) => (
                  <div className="item" key={index}>
                    <div className="usercard mb-3">
                      <div className="card-body">
                        <p className="card-text">
                          <img src={IMAGE.quoteImage} className="quoteimg" alt="" />
                        </p>
                        <div className="mt-3">
                          {testimonial.text}
                        </div>
                        <div className="fw-bold fs-6 lh-base text-start">
                          {testimonial.name}, {testimonial.location}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      </div>
      <DownloadPage />
    </>
  );
}

export default ReviewPage;
