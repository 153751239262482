import logo from "./logo.png";
import HomeBg from "./bg.png";
import HomeMobile from "./ImageView.png";
import about from "./about.png";
import featureMobile from "./featureMobile.png";
import quoteImage from "./quoteImg.svg";
import appleDownload from "./appleDownload.png";
import playStoreDownload from "./playStoreDownload.png";
import DownloadMobile from "./downloadMobile.png";
import FooterLogo from "./footerLogo.png";
import RightArrow from "./rightArrow.svg";
import RightArrowGray from "./rightArrowGray.svg";
import callIcon from "./call.svg";
import smsIcon from "./sms.svg";
import locationIcon from "./location.svg";
import aboutBg from "./aboutImage.svg";

export const IMAGE = {
    logo,
    HomeBg,
    HomeMobile,
    about,
    featureMobile,
    quoteImage,
    appleDownload,
    playStoreDownload,
    DownloadMobile,
    FooterLogo,
    RightArrow,
    RightArrowGray,
    callIcon,
    smsIcon,
    locationIcon,
    aboutBg
};