import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE } from "../assests/images";
import Footer from "./Footer";
import { scrollToTop } from "./Header";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop()
  }, [])

  const privacyData = [
    {
      "title": "1. Introduction",
      "subtitle": "This Privacy Policy outlines, uses, discloses, and protects your information when you visit our website Price Peek or use our services. We are committed to safeguarding your privacy and ensuring that your personal information is handled responsibly."
    },
    {
      "title": "2. Information We Collect",
      "subtitle": "Personal Information: Data that identifies you, such as your name, email address, and contact details."
    },
    {
      "title": "3. Data Security",
      "subtitle": "We implement reasonable measures to protect your personal information. However, no system is entirely secure, and we cannot guarantee complete security."
    },
    {
      "title": "4. Changes to This Policy",
      "subtitle": "We may update this Privacy Policy periodically. We will notify you of significant changes by posting the updated policy on our site."
    }
  ];

  return (
    <div id="Privacy">
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container p-3">
            <div onClick={() => navigate("/")}>
              <a className="navbar-brand" href="#Home">
                <img src={IMAGE.logo} alt="Logo" />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse justify-content-end navbar-collapse "
              id="navbarNav"
            >
              <div className="" onClick={() => navigate("/")}>
                <ul className="navbar-nav ml-lg-auto ms-2">
                  {" "}
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Review">
                      Review
                    </a>
                  </li>
                  <li className="nav-item mb-lg-0 mb-3">
                    <a href="#Download">
                      <button className="btn appbtn m-auto ms-lg-3 appbtntext">
                        <span className="appbtntext"> Download App</span>
                      </button>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#contact" className="text-decoration-none">
                      <button className="nav-link btn ms-lg-3 contactbtn">
                        <span className="contactbtntext"> Contact Us</span>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="terms-container p-lg-5 p-2">
        <div className="container terms-data">
          <h1 className="terms-header">Privacy <span>Policy</span></h1>
          {privacyData?.map((data, index) => (
            <div className="mt-1" key={index}>
              <p className="terms-text-title">{data.title}</p>
              <p className="terms-sub-text">{data.subtitle}</p>
            </div>
          ))}
          <div className="mt-5">
            <p className="terms-bottom-text">
              “Feel free to adjust the sections and details to fit your specific needs!”
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
