import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { IMAGE } from "../assests/images";
import "../styles/style.css";
import HomePage from "./Home";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Header = () => {
  const [showBackToTop, setShowBackToTop] = useState(true);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const handleScroll = () => {
    if (window.scrollY > 70) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container p-3">
            <div>
              <a className="navbar-brand" href="#Home">
                <img src={IMAGE.logo} alt="Logo" />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleToggle}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse justify-content-end navbar-collapse"
              id="navbarNav"
            >
              <div className="">
                <ul className="navbar-nav ml-lg-auto ms-2">
                  {" "}
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext mt-4 mt-lg-0" href="#Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Review">
                      Review
                    </a>
                  </li>
                  <li className="nav-item">
                    {!isNavbarOpen ?
                      <a href="#Download" className="text-decoration-none"><button className="btn appbtn ms-lg-3 appbtntext">
                        <span className="appbtntext">Download App</span>
                      </button></a> :
                      <a className="nav-link headertext" href="#Download">
                        Download App
                      </a>
                    }
                  </li>
                  <li className="nav-item">
                    {!isNavbarOpen ?
                      <a href="#contact" className="text-decoration-none">
                        <button className="nav-link btn ms-lg-3 contactbtn">
                          <span className="contactbtntext">Contact Us</span>
                        </button>
                      </a> :
                      <a className="nav-link headertext" href="#contact">
                        Contact Us
                      </a>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <HomePage />
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} color="white" size="lg" />
        </button>
      )}
    </>
  );
};

export default Header;
