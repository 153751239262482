import React from "react";
import { IMAGE } from "../assests/images";
import "../styles/style.css";
import Footer from "./Footer";

const DownloadPage = () => {
  const playStoreUrl = "";
  const appStoreUrl = "";

  return (
    <>
      <div className="downloadpage-container p-lg-5 p-5" id="Download">
        <div className="container">
          <div className="d-flex row justify-content-center align-items-center">
            <div className="col-lg-6 text-center text-lg-start">
              <img src={IMAGE.DownloadMobile} alt="Mobile" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <div>
                <div className="feature-page-content ps-3">
                  <h1 className="reviewicon">
                    Download Our App
                  </h1>
                </div>
                <div className="p-3">
                  <p className="downloadparagrph">
                    Unlock a world of features and seamless functionality by downloading our app! Stay connected, access exclusive content, and enjoy a user-friendly experience designed to enhance your daily life. Don’t miss out—join our community and take the first step toward convenience and innovation!
                  </p>
                  <div className="subdownloadparagrph">
                    Experience convenience at your fingertips—download our app today!
                  </div>
                  <div className="d-flex flex-wrap flex-column flex-lg-row gap-4 text-center">
                    <a
                      href={appStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={IMAGE.appleDownload}
                        alt="Download on the App Store"
                        className="mt-5"
                      />
                    </a>
                    <a
                      href={playStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={IMAGE.playStoreDownload}
                        alt="Download on Google Play"
                        className="mt-lg-5 mt-sm-0"
                      />
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ContactPage /> */}
      <Footer />
    </>
  );
}

export default DownloadPage;
