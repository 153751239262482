import React from "react";
import { IMAGE } from "../assests/images";
import "../styles/style.css";
import AboutPage from "./About";

const HomePage = () => {
  return (
    <>
      <div className="homepage-container p-lg-5 p-3" id="Home">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="text-container">
                  <div>
                    <span className="fonttext">Maximize Savings on Every Grocery Run.</span>
                  </div>
                  <h5 className="homepara mt-2">
                    Price Peek revolutionizes grocery shopping by helping you compare prices at your local stores in real time. With access to 160+ stores in 30+ locations, you can instantly see where your favorite items are cheapest.
                  </h5>
                  <h5 className="homepara-sub">
                      Not only that, but the app automatically suggests cheaper alternatives for your regular purchases, making it easier than ever to switch to budget-friendly brands. By streamlining price comparison and offering smart substitutions, Price Peek helps you save big every week, with users seeing an average of $25 saved per order. Start making smarter grocery decisions and enjoy effortless savings today!
                    </h5>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center">
                <img src={IMAGE.HomeMobile} alt="Mobile" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
      <AboutPage />
    </>
  );
};

export default HomePage;
