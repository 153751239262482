import React from "react";
import { IMAGE } from "../assests/images";
import FeaturePage from "./Features";

const AboutPage = () => {
  return (
    <>
      <div className="p-lg-5 p-3" id="About">
        <div className="container">
          <div className="feature-page-content">
            <h1 className="abouticon">
              About Us <span className="about-price">Price</span><span className="about-peek">Peek</span><br />
              Peek, Compare, Save!
            </h1>
            <h1 className="abouticon">
            </h1>

          </div>
          <div className="text-center mb-lg-5 mb-0">
            <p className="aboutheading">
              Passionate creators dedicated to delivering exceptional experiences
            </p>
          </div>
          <div className="container about-bottom">
            <div className="d-flex row justify-content-around align-items-center aboutpage-container">
              <img src={IMAGE.aboutBg} alt="about" className="img-fluid about-image d-none d-lg-flex" />
              <div className="col-lg-5 d-flex justify-content-center align-items-center mb-5 mb-lg-0 mt-0">
                <img src={IMAGE.about} alt="Mobile" className="img-fluid" />
              </div>
              <div className="col-lg-7">
                <div className="aboutparagph">
                  <p className="firstText">
                    At <strong>Price Peek,</strong> we believe grocery shopping should be smart, simple, and budget-friendly. Our mission is to help you save both time and money by providing real-time price comparisons for your local grocery stores. With our app, you can find the best deals at <strong>your local grocery stores,</strong> and we go a step further by recommending affordable alternatives for your favorite products, ensuring you always get the best value.</p>
                  <p className="firstText">
                    We’re passionate about making everyday shopping more efficient and accessible for everyone. Whether you're a savvy saver or just looking to cut down on expenses, Price Peek gives you the tools to make informed decisions and enjoy <strong>weekly savings.</strong> Since launching, our users have saved over <strong>$43,625</strong> on groceries, and we're just getting started.</p>
                  <div className="secondText">
                    “ At the core of Price Peek is a commitment to helping communities shop smarter, because we know that every dollar saved makes a difference. Join us, and start making your grocery budget go further. ”
                  </div>
                </div>
              </div>
            </div>
            <div className="d-md-flex justify-content-around align-items-center bottom-div">
              <p className="text-md-center m-3">30+ <br /> <span>Locations</span></p>
              <p className="text-md-center m-3">160+ <br /> <span>Stores</span></p>
              <p className="text-md-center m-3">500,000+ <br /> <span>Products</span></p>
            </div>
          </div>
        </div>
      </div>
      <FeaturePage />
    </>
  );
}

export default AboutPage;
