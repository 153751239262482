import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE } from "../assests/images";
import Footer from "./Footer";
import { scrollToTop } from "./Header";

const TermsCondition = () => {
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop()
  }, [])

  const termsData = [
    {
      "title": "1. Acceptance of Terms",
      "subtitle": "By using our services, you confirm that you have read, understood, and agree to be bound by these terms. If you do not agree, please do not use our services."
    },
    {
      "title": "2. User Accounts",
      "subtitle": "You may need to create an account to access certain features. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account."
    },
    {
      "title": "3. Use of Services",
      "subtitle": "Our services are intended for personal use only. You agree not to use them for any illegal or unauthorized purposes and to comply with all applicable laws."
    },
    {
      "title": "4. Intellectual Property",
      "subtitle": "All content, features, and functionality on our platform are the exclusive property of our company. You may not reproduce, distribute, or create derivative works without our express written consent."
    },
    {
      "title": "5. Third-Party Links",
      "subtitle": "Our platform may contain links to third-party websites. We are not responsible for the content or practices of those sites. Please review their terms and conditions before engaging with them."
    },
    {
      "title": "6. Limitation of Liability",
      "subtitle": "To the fullest extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising from your use of our services."
    },
    {
      "title": "7. Modifications",
      "subtitle": "We reserve the right to modify these terms at any time. We will notify you of any significant changes, and your continued use of our services constitutes acceptance of the revised terms."
    },
    {
      "title": "8. Governing Law",
      "subtitle": "These terms are governed by the laws of [Your Jurisdiction]. Any disputes arising from these terms will be resolved in the courts of [Your Jurisdiction]."
    },
    {
      "title": "9. Contact Us",
      "subtitle": "If you have any questions about these terms, please contact us at [Your Contact Information]."
    }
  ];

  return (
    <div id="Terms">
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container p-3">
            <div onClick={() => navigate("/")}>
              <a className="navbar-brand" href="#Home">
                <img src={IMAGE.logo} alt="Logo" />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse justify-content-end navbar-collapse "
              id="navbarNav"
            >
              <div className="" onClick={() => navigate("/")}>
                <ul className="navbar-nav ml-lg-auto ms-2">
                  {" "}
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Review">
                      Review
                    </a>
                  </li>
                  <li className="nav-item mb-lg-0 mb-3">
                    <a href="#Download">
                      <button className="btn appbtn m-auto ms-lg-3 appbtntext">
                        <span className="appbtntext"> Download App</span>
                      </button>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#contact" className="text-decoration-none">
                      <button className="nav-link btn ms-lg-3 contactbtn">
                        <span className="contactbtntext"> Contact Us</span>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="terms-container p-lg-5 p-2">
        <div className="container terms-data">
          <h1 className="terms-header">Terms & <span>Condition</span></h1>
          {termsData?.map((data, index) => (
          <div className="mt-3" key={index}>
            <p className="terms-text-title">{data.title}</p>
            <p className="terms-sub-text">{data.subtitle}</p>
          </div>
          ))}
          <div className="mt-5">
            <p className="terms-bottom-text">
              "By using our services, you acknowledge that you have read and understood these terms and agree to be bound by them."
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsCondition;
