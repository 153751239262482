import { Route, Routes, useSearchParams } from "react-router-dom";
import Header from "./pages/Header";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";

function App() {
  const url = useSearchParams();
  console.log(url, "rul");
  return (
    <Routes>
      <Route path="/" element={<Header />}></Route>
      <Route path="/terms" element={<TermsCondition />}></Route>
      <Route path="/privacy" element={<PrivacyPolicy />}></Route>
    </Routes>
  );
}

export default App;
